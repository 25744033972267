import { Component } from '@angular/core';
import { CommonService } from './common-service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'booking-engine';
}
