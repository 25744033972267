import { DatePipe } from '@angular/common';
import { BaseServices } from './base.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { environment } from '../../environments/environment'
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  BASE_URL = environment.BASE_URL
  private SearchParams = new BehaviorSubject<searchParam>({ 'open': false, })
  private loginParams = new BehaviorSubject<searchParam>({ 'loginStatus': false, })
  hotelInfoUpdated = new Subject();
  arrival: string
  departure :string
  roomsData: any;
  bookedRoom : any
  totalRoomRent : number
  dealsData : any
  selectedDeals : Array<any> = []
  termsCondition : any
  totalDealCharge: number
  taxRange: any
  homeUrl : any
  symbol: any;
  conversionRate : number = 1
  HotelId: any;
  address : any;
  socialMedia :any;
  CheckInOutTime:any;
  CheckInOutTime1:any;
  bookingType: any;
  nights : number;
  loginId: any;
  HotelName:string;
  Email:string;
  Address:string;
  MobileNo:string;
  hotelDetails: any;
  DetailPro: Array<any> = [];
  Branchlist: Array<any> = [];
  hotelImageList: Array<any> = [];
  nightAuditDate: any;
  selectedHotelId:any;
  companyDetail:any;
  constructor(
    public baseServices : BaseServices,
    public datepipe : DatePipe
  ) {
  }
   formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
}
getNightAuditDateForDateField() {
  return moment(this.nightAuditDate).format('yyyy-MM-DD');
}
  getCommonUtilityDetails(){
    this.getHotelId().subscribe((x) => {
      if (x.Status === 0) {        
        if (x.Data.HotelId && x.Data.HotelId > 0) {
          this.selectedHotelId=x.Data.HotelId;
          this.getHotelInfo(x.Data.HotelId).subscribe((y) => {
            this.hotelDetails = {...y.Data}
            this.Branchlist=this.hotelDetails.BranchDetails[0];
            this.CheckInOutTime=this.hotelDetails.CheckInOutTime[0];
            this.CheckInOutTime1=this.hotelDetails.CheckInOutTime[1];

            this.hotelImageList=this.hotelDetails.ImageList;
            
            if (this.hotelDetails.CurrencyList[0] && this.hotelDetails.CurrencyList[0].length > 0) {
              let defaultCurrency = this.hotelDetails.CurrencyList[0].filter(currency => currency.Default === 0);
              this.currencyChange(defaultCurrency[0].Id,defaultCurrency[0].Symbol,defaultCurrency[0].SelfRate);
            }
            this.hotelInfoUpdated.next()
          });
          this.getPropertyAll(x.Data.HotelId).subscribe((q) => {
           // this.DetailPro =[]
            this.DetailPro = q.Data;
          });
        }
      }
    })
  }
  initCompanyDetail(data) {
    this.companyDetail = data;   
  }
  getHotelId=()=>{
    return this.baseServices.getRequest(this.BASE_URL + '/api/Authentication/common/gettokendetail')
  }

  getHotelInfo=(HotelId)=>{
    this.HotelId =HotelId
    return  this.baseServices.getRequest(this.BASE_URL+'/api/BranchValidate/BranchVaildate?HotelId='+HotelId)
  }

  getPropertyAll=(HotelId)=>{
    this.HotelId =HotelId
    return  this.baseServices.getRequest(this.BASE_URL+'/api/PropertyBooking/PropertyAll?Token='+HotelId)
  }
  getPropertySingle=(HotelId)=>{
    this.HotelId =HotelId
    return  this.baseServices.getRequest(this.BASE_URL+'/api/PropertyBooking/PropertySingle?Token='+HotelId)
  }
  getLoginStatus(loginId,psw?){
    let url = this.BASE_URL+`/api/v1/customerloginresv?LoginId=${loginId}&HotelId=${this.HotelId}`
    if(psw){
          url = this.BASE_URL+`/api/v1/customerloginresv?LoginId=${loginId}&HotelId=${this.HotelId}&Password=${psw}`
          }
    return  this.baseServices.getRequest(url)
  }
  registerNewUser(loginId,psw){
    let url = this.BASE_URL+`/api/v1/customerloginresv?LoginId=${loginId}&HotelId=${this.HotelId}&Password=${psw}`
    return  this.baseServices.putRequest(url)
  }
  getRoomInfo(searchParams){
    this.arrival = searchParams.arrival
    this.departure = searchParams.departure
    let url = this.BASE_URL+`/api/RoomUtility/RoomUtility?SourceId=2&FromDate=${this.arrival}&ToDate=${ this.departure}&CurrencyId=${searchParams.currencyId}&RoomCategoryId=${searchParams.RoomCategoryId}&RoomPlanId=${searchParams.RoomPlanId}&AmountFrom=${searchParams.AmountFrom}&AmountTo=${searchParams.AmountTo}`;
    return this.baseServices.getRequest(url)
  }
   postBookingInfo=(body)=>{
     return this.baseServices.postRequest(this.BASE_URL+'/api/PmsReservation/PmsReservationFromBody',body)
 }
 Bookingenquiry=(body)=>{
  return this.baseServices.postRequest(this.BASE_URL+'/api/BookingEnquiry/BookingEnquiry',body)
}
  validateVoucher(code){
    let url = this.BASE_URL+`/api/v1/validateresvoffer?RoomcatId=0&RoomplanId=0&CouponCode=${code}&FromDate=${this.arrival}&ToDate=${this.departure}`;
    return this.baseServices.getRequest(url)
  }
 
  GetMasterSettingList=()=>{
    return this.baseServices.getRequest(this.BASE_URL + '/api/Master/GetMasterSettingList')
  }
  getVoucherList(){
    let url = this.BASE_URL+`/api/CommonUtilityAPI/ResvOfferList?FromDate=${this.arrival}&ToDate=${this.departure}`;
    return this.baseServices.getRequest(url)
  }
  getBookingList(){
    return this.baseServices.getBookingList(this.BASE_URL+`/api/v1/reservation?MobileNo=${this.loginId}`)
  }
  cancelBooking(id){
    return this.baseServices.putBookingList(this.BASE_URL+`/api/v1/reservation?Id=${id}`)
  }

  currencyChange(Id,symbol,rate) {
    this.symbol= symbol
    this.SearchParams.next({ 'open': true,'currencyId':Id,'symbol':symbol,'rate':this.conversionRate})
  }

  closeSearch() {
    this.SearchParams.next({ 'open': false })
  }

  searchStatus(): Observable<any> {
    return this.SearchParams.asObservable()
  }
  loginDetail(loginId,status,name){
    this.loginParams.next({'loginId': loginId,'loginStatus':status,'userName':name})
  }
  isLoginStatus(): Observable<any> {
    return this.loginParams.asObservable()
  }

  /*Getting AccessKey for Nimbble Gateway Integration MarketPlaceId will be 11 for nimbble and 5 for payou */
  getNimbbleAccessKey(){
    return  this.baseServices.getRequest(this.BASE_URL+'/api/PaymentGatway/PaymentGatwayKeynimbleBooking?MarketPlaceId=11')
  }

  /* Getting type of payment gateway which to integrate on basis of hotelId*/
  getPaymentGatewayType(hotelId){
    return  this.baseServices.getRequest(this.BASE_URL+'/api/BranchValidate/PaymentGatwayType?HotelId='+hotelId)
  }

  /* Getting Nibble Created Order Info using local orderId and marketPlaceId */
  getNibbleBookingToken(marketPlaceId, orderId){
    return  this.baseServices.getRequest(`${this.BASE_URL}/api/PaymentGatway/GeneratePaymentTokenBooking?MarketPlaceId=${marketPlaceId}&OrderId=${orderId}`)
  }

  postNibbleCheckoutStatus(payLoad){
    //use Hdfc
    return  this.baseServices.postRequest(`${this.BASE_URL}/api/BranchValidate/PaymentGatwayOrderStatus`, payLoad)

    
  }
  postNibbleCheckoutStatus1(payLoad){
    //Use Nimble
    return  this.baseServices.postRequest(`${this.BASE_URL}/api/guestdetails/OrderStatusBooking`, payLoad)
  }
}

export interface searchParam {
  open?: boolean
  currencyId? : string
  symbol? : string
  rate? : number
  loginId?:string
  loginStatus?:boolean
  userName?:string
}

