<div class="def-tp-gap container pt-5 pb-5">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="conf-heading">
        <i class="fa fa-check-circle-o bcicon"></i>
        <div class="ml-3">
          <h4>Thank You{{Party}}</h4>
          <p class="mb-0">YOUR BOOKING HAS {{BookingNo?.length===0?'NOT':''}} BEEN {{Msg}}
            <p>
        </div>
      </div>

      <div class="vres-bcdescription">
        <!-- vres-bcdescription -->

        <p>
          Your booking  {{BookingNo?.length===0?'enquiry':''}} request has been {{Msg}}.Your Booking
          {{BookingNo?.length===0?'Enquiry':''}} ID is : {{BookingNo?.length===0?OrderId:BookingNo }}

          and Your Order No is :{{OrderId?.length===0?"0":OrderId }} and Your Order Amount is : {{Amount?.length===0?"0":Amount}}
          {{Msg}}
        </p>

        <p>
          In case of any further communication for this transaction, please use the
          above provided Booking {{BookingNo?.length===0?'Enquiry':''}} ID
        </p>
        <p class="conf-hlt text-center">
          Your booking voucher has been successfully generated. You will receive the
          booking enquiry voucher on your email soon.
        </p>
        <p class="moreinquiry mt-4">
          For any other queries or doubts,<br />
          Do not hesitate to <b>call on : {{commonService?.address?.mobileNo}}</b><br />
          Or drop an <b>email at: {{commonService?.address?.email}}</b>
        </p>
        <div class="text-center mt-4" id="BookanotherButton">
          <!--kishan Tailor 23 july 2020 purpose:Added BookanotherButton id only for ePayment link feature guest portal ABS-5226-->
          <!-- <a class="btn btn-lg btn-primary" href='http://booking.tb24x7.com/home?Token={{token}}'>Make another booking</a> -->
          <a class="btn btn-lg btn-primary" [href]="this.Website">Home</a>
          <!-- <a
            href="https://www.ezeereservation.com/demo/"
            class="subhead-btn subhead-btn-bg chk-avail"
            >Return to Website</a
          > -->
        </div>
      </div>
    </div>
  </div>

</div>
