import { ToastrCustomService } from './../common-service/toastr.service';
import { CommonService } from './../common-service/common.service';
import { Component, OnInit } from '@angular/core';
declare const $: any
@Component({
  selector: 'app-cancel-list',
  templateUrl: './cancel-list.component.html',
  styleUrls: ['./cancel-list.component.css'],
})
export class CancelListComponent implements OnInit {
  bookingList: any;
  cancelId: any;

  constructor(public commonService: CommonService,
    public toastrService:ToastrCustomService) {
    this.getList()
  }

  ngOnInit(){

  }
  getList(){
    this.commonService.getBookingList().subscribe((res) => {
      if (res.Status === 0 && res.Data) {
        this.bookingList = res.Data
        console.log(this.bookingList)
      }
    });
  }
  cancelProcess(id) {
    this.cancelId = id
    $('#cancel_confirm').modal('show')
  }
  yesConfirmationCancel(){
    this.commonService.cancelBooking(this.cancelId).subscribe((res) => {
      if (res.Status === 0 && res.Data) {
        this.toastrService.showSuccess('Booking Cancelled Successfully','')
        console.log(res)
        this.getList()
      }else{
        this.toastrService.showError(res.Description,'')
      }
    });
    $('#cancel_confirm').modal('hide')
  }
}
