<header>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top">   
        <a class="navbar-brand" style="z-index: 9999;" href={{homeUrl}}>{{commonService?.hotelDetails?.HotelName}}
            <img src={{commonService?.Branchlist?.logo}} alt="" class="img-fluid logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>  
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            
            <div class="drop-box py-2" style="margin-left: 130px;">
                        <div class="col-md-12">
                        
                        <select class="form-control form-select" [(ngModel)]="commonService.selectedHotelId"  (change)="navigateTo($event)" *ngIf="commonService?.DetailPro && commonService?.DetailPro.length>1">
                                        <option [ngValue]="undefined" selected>Select Property</option>
                                        <option *ngFor="let Vendor of commonService?.DetailPro" [value]="Vendor.HotelId"
                                            [textContent]="Vendor.HotelName">Cash</option>
                                        </select>
                        </div>
                    </div>

            <ul class="navbar-nav ml-auto">
                
                <li class="nav-item active">
                    <a class="nav-link" href={{homeUrl}}><i class="fa fa-home"></i> Home <span
                            class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-target="#myModal" data-toggle="modal"><i class="fa fa-info-circle"></i>
                        Hotel Info</a>
                </li>

                <li *ngIf="loggedIn===false" class="nav-item dropdown d-none">
                    <a class="nav-link icon dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" data-target="#login_dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-lock" style="font-size:18px;"></i> Login
                    </a>
                    <div id=login_dropdown class="dropdown-menu p-3" aria-labelledby="navbarDropdown">
                        <form class="form-inline my-2 my-lg-0">
                            <label *ngIf="loginIdFilled===false"> LoginId</label>
                            <input *ngIf="loginIdFilled===false" class="form-control form-control-sm my-2" type="text"
                                placeholder="Enter Mobile No." aria-label="loginId" name="loginId"
                                [(ngModel)]="loginID">
                            <label *ngIf="newUser===true">New User Login</label>
                            <input *ngIf="loginIdFilled===true" class="form-control form-control-sm my-2"
                                type="password" placeholder="Enter Password" aria-label="password" name="password"
                                [(ngModel)]="password">
                            <input *ngIf="newUser===true && loginIdFilled===true" (ngModelChange)="match($event)"
                                class="form-control my-2" type="password" placeholder="Confirm Password"
                                aria-label="confirmPassword" name="confirmPassword" [(ngModel)]="confirmPassword">
                            <div *ngIf="pswMisMatch===true" style="color: red;">Password Mismatch</div>
                            <div *ngIf="validate===false" style="color: red;">Please Fill All Field </div>
                            <button *ngIf="loginIdFilled===false" [disabled]="!loginID"
                                class="btn btn-sm btn-primary w-100 my-2 my-sm-0"
                                (click)="getLoginStatus()">Next</button>
                            <button *ngIf="newUser===false && loginIdFilled===true"
                                class="btn btn-sm btn-primary w-100 my-2 my-sm-0" type="submit"
                                [disabled]="password.length===0" (click)="login()">Login</button>
                            <button *ngIf="newUser===true && loginIdFilled===true"
                                class="btn btn-sm btn-primary w-100 my-2 my-sm-0" [disabled]="pswMisMatch" type="submit"
                                (click)="registerUser()">Sign-Up</button>
                        </form>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link icon dropdown-toggle" href={{homeUrl}} id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-envelope cal-size" style="font-size:16px !important;"></i> Contact Us
                    </a>
                    <div class="dropdown-menu p-3 header-contact" aria-labelledby="navbarDropdown">
                        <form class="vres-form" id="enquire_form" onsubmit="validateenquiryform();return false;"> 
                        
                            <div class="enq-left pad-right">
                                <div class="form-wrap">
                                   <label for="encname" class="sr-only-k form-lbl">Name</label>
                                    <input type="text" name="encname" id="encname"   [(ngModel)]="PartyName"
                                    required
                                  
                                    [ngClass]="{'error': submitClick &&  !PartyName}">
                                    
                                    <span class="mandatory">*</span>
                                </div>
                                <div class="form-wrap">
                                   <label for="phone" class="sr-only-k form-lbl">Phone</label>
                                    <input name="phone" id="phone" type="text"  [(ngModel)]="Email"
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    [ngClass]="{'error': submitClick &&  !Email}">
                                    <span class="mandatory">*</span>
                                </div>
                                <div class="form-wrap">
                                   <label for="encemail" class="sr-only-k form-lbl">Email</label>
                                    <input name="encemail" id="encemail" type="text"   [(ngModel)]="Phone" required
                                    [ngClass]="{'error': submitClick &&  !Phone}">
                                    
                                    <span class="mandatory">*</span>
                                </div>
                                <div class="vres-checkavail" id="checkavaildatepcker1">
                                    <div class="input-daterange">
                                        <label class="vres-date" for="chk_in">
                                            <div class="input-group date">
                                                <label class="sr-only-k form-lbl">Form</label>
                                                <!-- <span class="input-group-addon"><i class="fa fa-calendar"></i></span> -->
                                                <input class="form-control" type="date" id="ToDate" [min]="today" (change)="departureDate1($event)" name="ToDate" [(ngModel)]="ToDate">
                                               
                                            </div>
                                        </label>
                                        <label class="vres-date" for="chk_out">
                                            <div class="input-group date">
                                                <label class="sr-only-k form-lbl">To</label>
                                                <!-- <span class="input-group-addon"><i class="fa fa-calendar"></i></span> -->
                                                <input class="form-control" type="date" id="FromDate" [min]="getNext(ToDate)"  name="FromDate" [(ngModel)]="FromDate">
                                               
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="enq-left pad-left">
                                <div class="form-wrap">
                                    <label class="vres-select">
                                        <label class="form-lbl">Adult(s)</label>
                                        <select id="eq_noofadult" name="eq_noofadult"   [(ngModel)]="Adults" required  [ngClass]="{'error': submitClick &&  !PartyName}">
                                            <!-- <option value="">Adult(s)</option> -->
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                        </select>
                                        <!-- <i class="fa fa-caret-down"></i> -->
                                        <span class="mandatory">*</span>
                                    </label>
                                </div>
                                <div class="form-wrap">
                                    <label class="vres-select">
                                        <label class="form-lbl">Child</label>
                                        <select id="eq_noofchild" name="eq_noofchild"   [(ngModel)]="Child" required  [ngClass]="{'error': submitClick &&  !PartyName}">
                                            <!-- <option value="">Child</option> -->
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                        </select>
                                        <!-- <i class="fa fa-caret-down"></i> -->
                                        <span class="mandatory">*</span>
                                    </label>
                                </div>
                                <div class="form-wrap">
                                    <label class="vres-select">
                                        <label class="form-lbl">Rooms</label>
                                        <select id="eq_noofrooms" name="eq_noofrooms"   [(ngModel)]="Rooms" required  [ngClass]="{'error': submitClick &&  !PartyName}">
                                            <!-- <option value="">Room(s)</option> -->
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                        </select>
                                        <!-- <i class="fa fa-caret-down"></i> -->
                                        <span class="mandatory">*</span>
                                    </label>
                                </div>
                                <div class="form-wrap">
                                    <label class="form-lbl">Message</label>
                                    <textarea name="encmessage" id="encmessage" rows="4"   [(ngModel)]="Message"></textarea>
                                 
                                </div>
                            </div>
   
                            <div class="enq-full pt-2">
                               <input type="submit" value="Enquire" id="sendenquiry" class="enquiry-btn-full"  (click)="submiten()">
                            </div>
                         </form>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-target="#myMap" data-toggle="modal">
                        <!-- <i class="fa fa-map-marker"></i> Map -->
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link">
                        <i class="fa fa-comment"></i> Reviews
                    </a>
                </li> -->
                <ng-container *ngIf="loggedIn===true">
                    <li class="nav-item ">
                        <a (click)="cancelBooking()" class="nav-link">
                            <i class="fa fa-calendar-times-o cal-size"></i> Cancel Booking
                        </a>
                    </li>
                </ng-container>
                <li class="nav-item dropdown" *ngIf="commonService?.hotelDetails?.CurrencyList && commonService?.hotelDetails?.CurrencyList.length>1">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Currency</a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <ul style="width: max-content;" class="currency p-0">
                            <li *ngFor="let currency of commonService?.hotelDetails?.CurrencyList;let i = index">
                                <a class="dropdown-item"
                                    (click)="changeCurrency(currency.Id,currency.Symbol,currency.SelfRate)">
                                    <span class="currency-name" style="color: rgb(94, 94, 255);">{{currency.Code}} -
                                    </span>
                                    <span>{{currency.Name}}</span></a>
                            </li>
                        </ul>

                    </div>
                </li>
                <li class="nav-item dropdown">
                    <div id="google_translate_element"></div>
                    <!-- <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">English</a>
                    <div class="dropdown-menu p-2" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item px-2 mb-1" href="#">English</a>
                        <a class="dropdown-item px-2 mb-1" href="#">Français</a>
                        <a class="dropdown-item px-2 mb-1" href="#">Italiano</a>
                        <a class="dropdown-item px-2 mb-1" href="#">Español</a>
                    </div> -->
                </li>
                <li *ngIf="loggedIn===true" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{userName}}</a>
                    <div class="dropdown-menu p-2" aria-labelledby="navbarDropdown">
                        <button (click)="logout()" class="dropdown-item px-2 mb-1" data-toggle="dropdown" href="#">Log
                            Out</button>
                    </div>
                </li>
            </ul>
            <form class="form-inline my-2 my-lg-0 d-none">
                <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
            </form>
        </div>
    </nav>
</header>

<!-- The hotel Modal -->
<div class="modal fade" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content hotel-info-model">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Hotel Information</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="container">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#home">Hotel Details & Policy</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu1">Hotel Gallery</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu2">Hotel Map</a>
                        </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div id="home" class="container tab-pane active" [innerHtml]="commonService?.Branchlist?.Hotelinfo | safe:'html'" style="white-space: pre-line">
                        </div>
                        <div class="container tab-pane fade text-center" id="menu1" style="white-space: pre-line">
                            <div class="slider-div">
                                <div id="carouselExampleControls-pop" class="carousel slide" data-ride="carousel" data-interval="500">
                                    <div class="carousel-inner">
                                        
                                        <div *ngFor=" let image of commonService?.hotelImageList; let i = index" class="carousel-item"
                                            [ngClass]="{'active': i === 0 }">
                                            <img src={{image.ImagePath}} alt="" class="img-fluid" />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleControls-pop" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleControls-pop" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="menu2" class="container tab-pane fade" [innerHtml]="commonService?.Branchlist?.Hotelmap | safe:'html'" style="white-space: pre-line">
                          xxxxxxxxxxxxxx

                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- myMap -->
<div class="modal fade" id="myMap">
    <div class="modal-dialog modal-lg">
        <div class="modal-content hotel-info-model">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Hotel Location</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div id="menu3" style="text-align: center;" [innerHtml]="commonService?.Branchlist?.Hotelmap | safe:'html'">
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>>
