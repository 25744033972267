import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../common-service/common.service';
import { BaseServices } from '../common-service/base.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  paymentSuccess: boolean;
  OrderId: any;
  BookingNo: any;
  token: any;
  Party: any;
  Amount:any;
  Msg:any;
  Status:any;
 Website:any;
  BASE_URL: string;
  HotelId:number;
  constructor(
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    public baseServices : BaseServices,
  ) { }
  getHotelId=()=>{
    return this.baseServices.getRequest(this.BASE_URL + '/api/Authentication/common/')
  }
  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((params) => {
      sessionStorage.setItem("Token", params.Token);
        let payload = {         
          OrderId: params.OrderId,
        }    
        setTimeout(() => {
          this.commonService.getHotelInfo(this.commonService.HotelId).subscribe((res) => {
         this.Website = res.Data.Website;
           })
        }, 2000);  
        this.commonService.postNibbleCheckoutStatus(payload).subscribe((res) => {
          if (res.Data[0].Party && res.Data[0].Party.length > 0) {
            console.log('res.Data[0].Party',res.Data[0].Party)
            this.Party = ', ' + res.Data[0].Party + '!'
          }
          else this.Party ='';
          if (res.Data[0].BookingNo && res.Data[0].BookingNo.length > 0) {
            this.paymentSuccess = true;
            this.BookingNo = res.Data[0].BookingNo;
          }
          else
          this.BookingNo ='0';

          if (res.Data[0].Amount && res.Data[0].Amount > 0) {
            this.Amount = res.Data[0].Amount;
          }
          if (res.Data[0].OrderId && res.Data[0].OrderId > 0) {
            this.OrderId = res.Data[0].OrderId;
          }
           else {
            this.paymentSuccess = false
          }         
          if (res.Data[0].Msg && res.Data[0].Msg=="Cancel") {
            this.Msg = "Cancel";
          }
          else{this.Msg = "Confirmed";}
        });
    });
  }
}
