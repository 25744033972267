<section class="rooms-information" style="padding-top: 70px">
  <div class="container py-4">
    <div class="row mb-4">
      <div class="col-md-4 left-column">
        <div class="card mb-3 mb-md-0">
          <div class="card-header">
            <h2 class="mb-0 pb-0">Your booking summary</h2>
            <div class="sum-htl-addr">
              <h5 class="mb-1">{{commonService?.Branchlist?.HotelName}}</h5>
              <p class="mb-0"><i
                  class="fa fa-map-marker"></i>{{commonService?.Branchlist?.Address}},{{commonService?.Branchlist?.CityName}},{{commonService?.Branchlist?.StateName}},{{commonService?.Branchlist?.CountryName}}
              </p>
              <p class="mb-0"><i *ngIf="commonService?.Branchlist?.MobileNo?.length>0" class="fa fa-phone"
                  aria-hidden="true"></i> {{commonService?.Branchlist?.MobileNo}}</p>
              <p class="mb-0"><i *ngIf="commonService?.Branchlist?.Email?.length>0" class="fa fa-envelope"
                  aria-hidden="true"></i> {{commonService?.Branchlist?.Email}}</p>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="payment-card p-0">
              <h3 class="mb-0 pb-0"> <span>Booking Type:</span>{{commonService?.bookingType}}</h3>
              <div class="pay-chkInOut_wrap">
                <p class="pay-chkInOut"><b>Check-In</b><span>{{commonService?.arrival}}- {{commonService?.CheckInOutTime?.RangeFrom}}</span></p>
                <p class="pay-chkInOut"><b>Check-Out</b><span>{{commonService?.bookingType==='Day Room'?commonService.arrival:commonService?.departure}}- {{commonService?.CheckInOutTime1?.RangeFrom}}</span></p>
              </div>
              <div class="pay-rm_detail">
                <div *ngFor="let room of bookedRoom;let i=index;">
                  <div *ngFor=" let occupancy of room.occupancy; let j =index;">
                    <div class="pax-detail">
                      <b>Room {{j+1}} </b><br />
                      Rateplan Name : {{room.RoomPlan}}<br>
                      <b>Pax Details :</b>{{occupancy.adults}} Adults , {{occupancy.childs}} Child
                      <ul class="pay-rm-rm-list">
                        <li> Room Charges</li>
                        <li>
                          <span class="price">
                            <span class="foreignrates vres-prices onlyroomcharge_0">{{currencySymbol}}
                              {{(room.Fare/conversionRate).toFixed(2)}}</span>
                          </span>
                        </li>
                        <li>
                          <span>Taxes &amp; Fees</span>
                        </li>
                        <li>
                          <span class="price">
                            <span class="foreignrates vres-prices roomtax_0">{{currencySymbol}}
                              {{(room.Tax/conversionRate).toFixed(2)}}</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="commonService?.selectedDeals?.length>0" style="color:#c66161">Extra Charges:</div>
                <div class="mb-0 extra-rm" *ngFor="let deals of commonService?.selectedDeals; let i  = index;">
                  <span class="ext-rm-nm">{{deals.Name}}&nbsp;</span><br>
                  <div class="d-flex">
                    <span>Pax: {{deals.Qty}} x <span> {{(deals.Price/conversionRate).toFixed(2)}} </span></span>
                    <span class="">Tax: {{((deals.TotalTax*deals.Qty)/conversionRate).toFixed(2)}}</span>
                    <span class="ml-auto">{{currencySymbol}}
                      {{(((deals.TotalTax+deals.Price)*deals.Qty)/conversionRate).toFixed(2)}}</span>
                  </div>
                </div>
              </div>
              <div class="total-paybleRm pt-3" id="payment_data_block">
                <p>
                  <span>Total Room Charges
                    <span *ngIf="discount===0" class="price">
                      <span class="foreignrates vres-prices" id="tot_roomcharge">{{currencySymbol}}
                        {{(totalFare/conversionRate).toFixed(2)}}</span>
                    </span>
                    <span *ngIf="discount>0" class="price">
                      <span class="foreignrates vres-prices" id="tot_roomcharge"><del>{{currencySymbol}}
                          {{(totalFare/conversionRate).toFixed(2)}}</del>&nbsp;{{(fareAfterDiscount/conversionRate).toFixed(2)}}</span>
                    </span>
                  </span>
                  <span>Total Taxes
                    <span *ngIf="discount===0" class="price">
                      <span class="foreignrates vres-prices" id="taxes">{{currencySymbol}}
                        {{(totalTax/conversionRate).toFixed(2)}}</span>
                    </span>
                    <span *ngIf="discount>0" class="price">
                      <span class="foreignrates vres-prices" id="taxes"><del>{{currencySymbol}}
                          {{(totalTax/conversionRate).toFixed(2)}}</del>&nbsp;{{(taxAfterDiscount/conversionRate).toFixed(2)}}</span>
                    </span>
                  </span>
                  <span *ngIf="commonService?.selectedDeals?.length>0">Extra Services
                    <span class="price">
                      <span class="foreignrates vres-prices" id="taxes">{{currencySymbol}}
                        {{(commonService.totalDealCharge/conversionRate).toFixed(2)}}</span>
                    </span>
                  </span>
                  <span>Total Price (Inc. Of Taxes)
                    <span class="price">
                      <span class="foreignrates vres-prices GrandTotal" id="grandtotal">{{currencySymbol}}
                        {{(totalCharge/conversionRate).toFixed(2)}}</span>
                    </span>
                  </span>
                </p>
                <h3 id="temp-price">
                  <div class="curr_div d-flex">
                    <span id="totalpaymentcaption">Total Payable Amount</span>
                    <span class="ml-auto mr-0">
                      <b class="foreignrates GrandTotal" id="totalpay">{{currencySymbol}}
                        {{(totalCharge/conversionRate).toFixed(2)}}</b>
                    </span>
                  </div>
                </h3>
              </div>
              <div *ngIf="appliedCode === false" class="total-paybleRm spe-ofer-sect" id="choosepromocode">
                <span class="promo-ofr-title">OFFERS :</span>
                <div *ngFor="let voucher of voucherList" class="promo-offer-list">
                  <label for="{{voucher.CouponCode}}">
                    <input id="{{voucher.CouponCode}}" [value]='voucher.CouponCode' type="radio" name="voucher"
                      [(ngModel)]="promo">
                    {{voucher.CouponCode}}
                  </label>
                </div>
              </div>
              <div *ngIf="appliedCode === false" class="promo-form-payment tipso-tooltip tipso_style mb-3"
                data-tipso="Discount Code / Voucher" id="promocodefrm">
                <input id="pcodebill" [(ngModel)]="promo" type="text" placeholder="Enter Promotional code"
                  name="promocode" />
                <button id="applypromocode" type="button" class="vres-btn btn btn-primary" (click)="applyPromo()">
                  Apply
                </button>
              </div>
              <ng-container *ngIf="appliedCode === true" class="vrt-links promo-code-sved saved_promo"
                id="appliedpromocode">
                <div class="promo-tag">
                  <i class="fa fa-check"></i> Applied Promotional Code
                  <a title="Promotional Discount" class="just-bk_info" id="billingpromocode">&nbsp;{{promo}}</a>
                </div>
              </ng-container>
              <ng-container *ngIf="appliedCode === true" id="saved_onapplypromo"
                class="vrt-links promo-code-sved saved_promo">
                <div class="promo-tag">
                  <span><i class="fa fa-check"></i> You Total Saving :
                    <span id="promo_ograndtotal" class="foreignrates">&nbsp;{{currencySymbol}}
                      {{(discount/conversionRate).toFixed(2)}}</span>
                  </span>
                </div>
              </ng-container>
              <div id="saved_onapplypromo" class="vrt-links promo-code-sved saved_promo">
                <div id="lookinguser" class="user_alert" align="center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="container">
          <div class="row add-extra-wrap pt-3">
            <div class="add-services_wrap">
              <div class="row mb-4 ml-3 mr-3 guest-information">
                <div class="col-md-12 pl-2 bdr-bttom pb-3">
                  <div class="guest-title mb-3"> Guest Information</div>
                  <form class="form-inline select-input-w-same" #guestInfo="ngForm">
                    <div class="w-100 d-block mb-3">
                      <label for="email" class="d-block w-100">Guest Name:</label>
                      <div class="d-flex">
                        <select class="form-control prefix_val mr-2" name="prefix" [(ngModel)]="prefix" [ngClass]="{ 'ng-select-invalid': submitClick && !prefix }">
                        <option *ngFor="let opt of commonService?.hotelDetails?.NameTitleList;"  [value]="opt.Name">{{opt.Name}}</option>
                        </select>
                        <input type="text" class="form-control flex-w-full" placeholder="Full Name" id="guestNameId"
                          name="guestName" [(ngModel)]="guestName" [ngClass]="{
                          'error': submitClick &&  !guestName
                        }">
                      </div>
                    </div>
                    <div class="w-100 d-block mb-3">
                      <label for="email" class="d-block w-100">Mobile:</label>
                      <div class="d-flex contry-val-gp">
                        <select class="form-control mr-2 prefix_cont-val">
                          <option>India (+91)</option>
                          <option>Usa (+1)</option>
                          <option>Afganistan (+93)</option>
                          <option>Australia (+61)</option>
                          <option>Canada (+1)</option>
                          <option>Colombia (+57)</option>
                          <option>United Arab Emirates (+971)</option>
                          <option>United Kingdom (+44)</option>
                          <option>Brazil (+55)</option>
                        </select>
                        <input type="number" class="form-control flex-w-full" placeholder="Enter Mobile No" id="guestMobileNoId"
                          name="mobileNo" [(ngModel)]="mobileNo" [ngClass]="{
                          'error': submitClick &&  !mobileNo
                        }">
                      </div>
                    </div>
                    <div class="w-100 d-block mb-3">
                      <label for="emailAddress" class="d-block w-100">Email:</label>
                      <input  #emailAdd="ngModel" type="email"
                        class="form-control w-100"
                        placeholder="Email address"
                        id="name"
                        name="emailAddress"
                        [(ngModel)]="email"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        [ngClass]="{'error': submitClick &&  !email}">
                        <p class="text-danger" *ngIf="emailAdd.touched && emailAdd.errors?.required">Email is a required field!</p>
                        <p class="text-danger" *ngIf="emailAdd.touched && emailAdd.errors?.pattern">This is not a valid Email!!!</p>
                      <p class="sub-tag">Your voucher will be sent to this email address</p>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row mb-4 ml-3 mr-3 payment-option">
                <div class="col-md-12 pl-2">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a class="nav-link active" role="tab" data-toggle="tab" data-target="#Booking">Terms-Condition</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="Booking"><br>
                      <h4> {{commonService?.Branchlist?.HeadingTearmandcondition}}</h4>
                      <p> {{commonService?.Branchlist?.Tearmandcondition}}</p>
                      <br>
                       <h4> {{commonService?.Branchlist?.HeadingDebitTearms}}</h4>
                      <p> {{commonService?.Branchlist?.DebitTearms}}</p>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="customControlAutosizing" name="termns"
                          [(ngModel)]="terms" [ngClass]="{
                        
                          'error': submitClick &&  !terms
                        }">
                        <label class="custom-control-label" for="customControlAutosizing">I acknowledge and accept the
                          Terms of Cancellation Policy & Hotel Policy . <span class="text-danger">*</span> </label>
                        <div class="row">
                          <div class="col-md-4">
                            <img src="../../assets/images/comodo_secure_100x85_white.png" class="ml-0" alt="">
                          </div>

                          <div class="col-md-8 text-right">
                            <div (click)="submit()" class="btn btn-warning ml-auto mt-3 text-white">Confirm and Pay
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pay"><br>
                      <h4> Credit Card Information </h4>
                      <form>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Name of Card Holder</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="cardHolderNameId" placeholder="">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Credit Card Number</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="creditCardNumberId" placeholder="">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Expiry Date</label>
                          <div class="col-sm-10">
                            <div class="form-row">
                              <div class="form-group col-md-4">
                                <select id="monthId" class="form-control">
                                  <option selected>MM</option>
                                  <option>...</option>
                                </select>
                              </div>
                              <div class="form-group col-md-4">
                                <select id="yearId" class="form-control">
                                  <option selected>YY</option>
                                  <option>...</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Verification Code</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="verificationCodeId" placeholder="Name">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Cards accepted at this hotel</label>
                          <div class="col-sm-10">
                            <img src="../../assets/images/cards.jpg" alt="">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
                          <div class="col-sm-10">
                            <input type="password" class="form-control" id="inputPassword" placeholder="Password">
                          </div>
                        </div>
                      </form> 
                     
                      <h4> {{commonService?.Branchlist?.HeadingDebitTearms}}</h4>
                      <p> {{commonService?.Branchlist?.DebitTearms}}</p>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="customControlAutosizing" name="termns"
                          [(ngModel)]="terms" [ngClass]="{
                          'error': submitClick &&  !mobileNo
                        }">
                        <label class="custom-control-label" for="customControlAutosizing">I acknowledge and accept the
                          Terms of Cancellation Policy & Hotel Policy . * </label>
                        <div class="row">
                          <div class="col-md-4">
                            <img src="../../assets/images/comodo_secure_100x85_white.png" class="ml-0" alt="">
                          </div>
                          <div class="col-md-8 text-right">
                            <div (click)="submit()" class="btn btn-warning ml-auto mt-3 text-white">Confirm and Pay
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
