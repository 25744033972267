<!-- <section class="fillter">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="hotel-fltter-bar bdr d-flex mt-4 p-2">
          <div class="hotel-info pl-2">
            <h6 class="mb-0">Not ready to submit your reservation?</h6>
          </div>
          <div class="reserce_details ml-auto px-2 p-2">
            <div class="col-auto">
              <button type="submit" class="btn btn-primary">
                Save for later
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="rooms-information" style="padding-top: 70px">
  <div class="container py-4">
    <div class="row mb-4">
      <div class="col-md-4 left-column">
        <div class="card mb-3 mb-md-0">
          <div class="card-header">
            <h2 class="mb-0 pb-0 text-center">Your booking summary</h2>
          </div>
            <div class="card-body">
              <div  class="sum-date">
                <span>Booking Type:</span>{{commonService.bookingType}}
              </div>
              <!-- <div  *ngIf="commonService.bookingType==='Day Room'"class="sum-date">
                <span>Date:</span>{{commonService.arrival}}
              </div> -->
              <div class="sum-date">
                <span>Dates:</span>{{commonService.arrival}} to {{commonService.bookingType==='Day Room'?commonService.arrival:commonService.departure}}
              </div>
              <div *ngIf="commonService.bookingType==='OverNight Stay'" class="sum-date">
                <span>Night:</span>{{commonService.nights}}
              </div>
              <div class="sum-room-detail"  *ngFor="let booking of bookedRoom; let i =index">
                <span class="sum-rm-cat">{{booking.Category}}</span> &nbsp;
               (<span class="sum-rm-name">{{booking.RoomPlan}}</span>)
               <!-- <span class="cls-btn"><i class="fa fa-close" (click)="deleteBookingRow(booking,i)"></i></span> -->
              <div class="sum-rm-pax-det" *ngFor="let room of booking.occupancy; let i = index">
              <div class="d-flex align-items-center">
                <div class="sum-rm-bk-date mr-2"><span>Room No. {{i+1}}</span>  </div>
                <div class="sum-rm-adult-sect">
                  <div class="sum-pax-adu">
                    <i class="fa fa-male mr-1"></i>{{room.adults}}
                  </div>
                  <div class="sum-pax-adu">
                    <i class="fa fa-child mr-1"></i> {{room.childs}}
                  </div>
                  <a  data-toggle="modal"
                  data-target="#breakdown" (click)="pricePopup(booking)">
                    Price Breakdown
                  </a>
                </div>
              </div>

              <div class="sum-rm-ad-rate-sec">
                <div class="sum-rm-rent-sect">
                <!-- <div class="sum-rm-rent-sect" *ngFor="let price of booking.Price[0]"> -->
                  <div class="sum-rm-rent-row">
                    <!-- <div class="sum-rm-rent-date mr-1">{{price.BookingDate}}<br>
                    {{price.NextDate}}
                    </div> -->
                    <div class="mr-1">
                      <b>{{currencySymbol}} {{(booking.TotalRent/conversionRate).toFixed(2)}}</b><br>
                      Room Rent
                    </div>
                    <div>
                      <b>{{currencySymbol}} {{(booking.TotalTax/conversionRate).toFixed(2)}}</b><br>
                      Tax Amount
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </div>
              <!-- <p class="d-flex">
                <span><b>{{booking.Category}}</b> </span>
              </p>
              <p class="d-flex" >
                <span><b>Price: </b>{{booking.Price*booking.NoOfRooms}}</span> <span class="ml-auto">No Of Room/s: {{booking.NoOfRooms}}</span>
              </p> -->

              <!-- <p class="d-flex">
                <span></span>
                <span class="ml-auto"><b>Total Room/s:</b> {{ totalbookedRoom }}</span>
              </p> -->


              <div class="d-flex rm-sm-tl-price mb-0">
                <span *ngIf="selecteddeals.length===0">Total Bill</span>
                <span *ngIf="selecteddeals.length>0">Room Rent</span>
                <span class="ml-auto">{{currencySymbol}} {{(commonService.totalRoomRent/conversionRate).toFixed(2)}}</span>
              </div>
              <div *ngIf="selecteddeals.length>0" class="red-tm-color">Extra Charges:</div>
              <div class="mb-0 extra-rm" *ngFor="let deals of selecteddeals; let i  = index">
                <span class="ext-rm-nm">{{deals.Name}}&nbsp;</span><br>
                <div class="d-flex">
                  <span>Pax: {{deals.Qty}} x <span> {{(deals.Price/conversionRate).toFixed(2)}} </span></span>
                  <span  class="" >Tax: {{((deals.TotalTax)*deals.Qty/conversionRate).toFixed(2)}}</span>
                <span  class="ml-auto" >{{currencySymbol}} {{(((deals.TotalTax+deals.Price)*deals.Qty)/conversionRate).toFixed(2)}}</span>
                </div>
              </div>
              <div *ngIf="selecteddeals.length>0" class="d-flex  final-amt-rw">
                <span >Total Bill</span>
                <span class="ml-auto">{{currencySymbol}} {{((commonService.totalRoomRent + totalDealCharge)/conversionRate).toFixed(2)}}</span>
              </div>
            </div>
          <div class="card-footer">
            <button class="btn btn-primary w-100 my-2 my-sm-0" (click)="book()" type="submit">
              Book Now
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-8">
<div class="border-box mb-2 d-flex">
  <div class="loyalpnt">
    <h6 class="mb-0">Reedem Your Loyalty Point
      <input type="text" class="pl-2"><button>Check Point</button>
     </h6>
  </div>
</div>



          <div class="add-extra-wrap">
            <div class="col mb-3 bdr-bttom">
              <h4 class="d-flex mb-3 mt-2">
                Optional Extras or Additional Services
                <!-- <span class="ml-auto mr-0"><a href="#"><i class="fa fa-times" aria-hidden="true"></i></a></span> -->
              </h4>
            </div>

            <div class="add-services_wrap">
              <div *ngFor="let deals of dealsData; let i = index" class="row mb-4 ml-0 mr-0 bdr-bttom">
                <div class="col-lg-4 col-md-12 pr-md-0">
                  <div class="room-img mb-3">
                    <img src={{deals.ImageName1}} class="img-fluid w-100" alt="" />
                  </div>
                </div>
                <div class="col-lg-8 col-md-12 pl-2">
                  <div class="card border-0 shadow-none">
                    <div class="card-body p-2">
                      <div class="row room-detail">
                        <div class="col-md-8">
                          <h5 class="title">{{deals.Name}}</h5>
                          <p class="mb-2">
                            {{deals.Description}}
                          </p>
                          <p class="mb-0">
                            <span class="text-danger">
                              {{deals.Note}}
                            </span>
                          </p>
                          <p class="mb-2">( Applicable everyday )</p>
                          <!-- <h6 class="mb-2">Room :</h6>
                          <p class="mb-2">Rateplan Name : Deluxe Double Room</p> -->
                          <div *ngIf="deals.added === true" class="form-group1 text-left">
                            <label for="birthday" class="text-left d-block ft-12"><b>Please Select No. of Persons:</b></label>
                            <select id="rm_adult_1_0"  class="custom-select mr-sm-2" (change)="QtySelect($event,deals)">
                              <option *ngFor="let opt of transformCount(totalOccupany);let last = last " [value]="opt+1" [selected]="last">{{opt+1}}</option>
                            </select>
                            <!-- <select class="custom-select mr-sm-2"  (change)="QtySelect($event,deals)" >
                              <option selected value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select> -->
                          </div>
                          <!-- <p class="text-danger">This field is required.</p> -->
                        </div>
                        <div class="col-md-4 mb-3 text-md-right">
                          <p class="mb-0">Total</p>
                          <div class="price mb-0">{{currencySymbol}} {{(deals.Price/conversionRate).toFixed(2)}}</div>
                          <p class="mb-2">
                            <span class="text-danger">All Tax Exclusive </span>
                          </p>
                          <p class="mb-0">
                            <button *ngIf="deals.added === false" class="btn"(click)="addDeal(deals,i)"
                            style="background: #2196F3 ;color: azure;"
                            > ADD</button>
                            <button *ngIf="deals.added === true"  class="btn" (click)="removeDeal(deals,i)"
                            style="background: #dc3545;color: azure;"> Remove</button>

                            <!-- <label class="switch">
                              <input type="checkbox" (click)="addRemoveDeal(deals,$event,i)">
                              <span class="slider">
                                <span class="on">Added</span><span class="off">Add</span>
                              </span>
                            </label> -->
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>
  <div *ngIf="pricePopupToShow" class="modal  right fade" id="breakdown">
    <div class="modal-dialog  model-full-height model-right hotel-info-model ">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">
         Price Breakdown
        </h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body" style="padding: 0px;">

        <div class="sum-rm-ad-rate-sec">
          <div class="sum-rm-rent-sect">
          <div class="sum-rm-rent-sect" *ngFor="let price of priceData.Price[0]">
            <div class="sum-rm-rent-row">
              <div class="sum-rm-rent-date mr-1">{{price.BookingDate}}<br>
              {{price.NextDate}}
              </div>
              <div class="mr-1">
                <b>{{currencySymbol}} {{(price.RoomRent/conversionRate).toFixed(2)}}</b><br>
                Room Rent
              </div>
              <div>
                <b>{{currencySymbol}} {{(price.TaxAmount/conversionRate).toFixed(2)}}</b><br>
                Tax Amount
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
