import { Injectable } from '@angular/core'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private router: Router){
  }

  getToken (): any {
    if (window.sessionStorage['Token']) {
      return window.sessionStorage['Token']
    } else {
      return false
    }
  }

  saveToken (Token: String) {
    window.sessionStorage['Token'] = Token
  }

  destroyToken() {
    window.sessionStorage.removeItem('Token')
    window.sessionStorage.clear();
    this.router.navigate(['login']);
  }
}
