import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpErrorResponse, HttpClient, HttpHeaders} from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GuestInfoComponent } from './guest-info/guest-info.component';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { ToastrModule } from 'ngx-toastr'
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeModule } from './home/home.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { CancelListComponent } from './cancel-list/cancel-list.component';
import { CommonService } from './common-service/common.service';
import { LoaderService } from './common-service/loader.service';
import { LoaderInterceptor } from './common-service/loader-interceptor.service';
import { environment } from 'src/environments/environment';
@NgModule({
  declarations: [
    AppComponent,
    GuestInfoComponent,
    BillingInfoComponent,
    ConfirmationComponent,
    CancelListComponent
    // HomeComponent
  ],
  imports: [
    HeaderModule,
    FooterModule,
    HomeModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    NgSelectModule,
    ToastrModule.forRoot(
      {
        timeOut: 1000,
        preventDuplicates: true
      }
    ),
  ],
  providers: [

    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private cs: CommonService,http: HttpClient,public CommonServic :CommonService){
    var url = window.location;
    var access_token = new URLSearchParams(url.search).get('Token');
    if(access_token){
      sessionStorage.setItem('token', access_token);
      sessionStorage.setItem('Token', access_token);
    }
    this.cs.getCommonUtilityDetails();  
    
    const promiseForCompanyDetail = new Promise((resolve, reject) => { 
      http.get(`${environment.BASE_URL}/api/Company/GetCompanyDetails`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',        
          "Authorization": new URLSearchParams(url.search).get('Token'),
        })
      }).toPromise()
        .then((result: any) => {       
          if (result) {
            CommonServic.initCompanyDetail(result);
            return resolve(result);
          }
        }).catch((err: HttpErrorResponse) => {
          return resolve({});
        });;
    });
  }
  }
